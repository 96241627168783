<template>
  <div class='system-ticket mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="60px" inline @submit.native.prevent size="small">
        <el-form-item label="场所:" prop="homeId">
            <el-select v-model="keyWord.homeId" placeholder="请选择场所" @change="getList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="编号:" prop="code">
          <el-input type="text" class="w180" v-model="keyWord.code" placeholder="请输入工单编号" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="时间:" prop="time">
          <el-date-picker class="timePicker" v-model="keyWord.time" value-format="timestamp" clearable :default-time="['00:00:00', '23:59:59']" @change="getList(1)" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
        </el-form-item>
        <el-form-item label="等级:" prop="level">
          <el-select v-model="keyWord.level" placeholder="等级" class="w100" @change="getList(1)" clearable>
            <el-option label="特急" value="1"></el-option>
            <el-option label="紧急" value="2"></el-option>
            <el-option label="较急" value="3"></el-option>
            <el-option label="一般" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="keyWord.status" placeholder="状态" class="w100" @change="getList(1)" clearable>
            <el-option label="待派单" value="0"></el-option>
            <el-option label="已派单待处理" value="1"></el-option>
            <el-option label="处理中" value="2"></el-option>
            <el-option label="已处理待审核" value="3"></el-option>
            <el-option label="已审核" value="4"></el-option>
            <!-- <el-option label="审核不通过" value="5"></el-option> -->
            <el-option label="已取消" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型:" prop="type">
          <el-select v-model="keyWord.type" placeholder="类型" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" v-if="isAuth('safe:system:ticket:add')" @click="openDio('add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
          <!-- <el-button type="text" class="error" @click="batchDel()"><i class="el-icon-delete"></i>批量删除</el-button> -->
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="ticketCode" label="工单编号" width="200" show-overflow-tooltip/>
          <!-- <el-table-column prop="homeName" label="场所"/> -->
          <el-table-column :formatter="formatStatus" label="状态" width="110"/>
          <el-table-column :formatter="formatType" label="类型" width="110"/>
          <el-table-column :formatter="formatLevel" label="等级" width="50"/>
          <!-- <el-table-column prop="" label="工单详情" width="300" show-overflow-tooltip/> -->
          <el-table-column :formatter="formatTime2" label="创建时间" width="150"/>
          <el-table-column prop="handlerName" label="处理人"/>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip/>
          <el-table-column label="操作" width="250" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:edit')&&(scope.row.ticketStatus=='0'||scope.row.ticketStatus=='1'||scope.row.ticketStatus=='3')" @click="openDio('edit',scope.row)"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:detail')" @click="openDio('detail',scope.row)"><i class="el-icon-view"></i>查看</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:handle')&&(scope.row.ticketStatus=='0'||scope.row.ticketStatus=='1'||scope.row.ticketStatus=='2')" @click="openDio('handle',scope.row)"><i class="el-icon-s-promotion"></i>处理</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:cancel')&&(scope.row.ticketStatus=='0'||scope.row.ticketStatus=='1'||scope.row.ticketStatus=='2')" @click="openDio('cancel',scope.row)"><i class="el-icon-error"></i>取消</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:audit')&&scope.row.ticketStatus=='3'" @click="openDio('audit',scope.row)"><i class="el-icon-s-platform"></i>审核</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:reorder')&&scope.row.ticketStatus=='5'" @click="openDio('reorder',scope.row)"><i class="el-icon-refresh-left"></i>重新派单</el-button>
              <el-button type="text" class="primary" v-if="isAuth('safe:system:ticket:withdrawAudit')&&scope.row.ticketStatus=='5'" @click="openDio('withdrawAudit',scope.row)"><i class="el-icon-s-release"></i>撤销审批</el-button>
              <!-- <el-button type="text" class="error" @click="openDio('del',scope.row)"><i class="el-icon-delete"></i>删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <ticket-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></ticket-add-edit>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import ticketAddEdit from './ticket-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'system-ticket',
  data() {
    return {
      //筛选数据
      keyWord :{
        homeId:'',//场所ID
        code:'',//工单编号
        time:'',//时间
        level:'',//等级
        status:'',//状态
        type:'',//类型
      },
      dicObj:{
        homeList:[],//场所数据
        typeData:[],//类型数据
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    ticketAddEdit,
    breadCrumb,
  },
  watch:{
    '$store.state.csType':function(newVal){
      if(newVal!=='0'){
        vm.keyWord.homeId = ''
        vm.getHome()
        vm.$nextTick(()=>{
          vm.getList(1)
        })
      }
    },
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
    vm.getCurrDics()
    vm.getHome()
  },
  mounted(){
    vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //获取场所
		async getHome(){
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if(res?.code == 200){
        this.dicObj.homeList = res.data
      }
		},
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteUser(arr)
          })
          .catch(() => {})
      }else if(type == 'withdrawAudit'){
        vm.$confirm('确定要撤销审批该工单？','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            vm.ticketRevoke(item);
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'||type=='detail'||type=='handle'||type=='audit'||type=='reorder'){
          vm.$refs.editDio.init(type,item);//新增、修改、查看、处理、审核、重新派单
        }else if(type=='cancel'){
          vm.cancelTicket(item);//取消工单
        }
      }
    },

    //撤销审批
    async ticketRevoke(item){
      const res = await apiUrl.ticketRevoke(item.id)
      if(res?.code==200){
        vm.$message.success('撤销成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //弹窗-输入取消原因
    cancelTicket(item){
      this.$prompt('请输入取消原因', '取消工单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /.+/,//不能为空
          inputErrorMessage: '取消原因必填'
        }).then(({ value }) => {
          vm.ticketCancel(item.id,value)
        }).catch(() => {});
    },
    //取消工单
    async ticketCancel(id,reason){
      let dataObj = {
        'id':id,
        'reason':reason
      }
      const res = await apiUrl.ticketCancel(dataObj)
      if(res?.code==200){
        vm.$message.success('取消成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteUser(arr);//删除
      }).catch(() => {});
    },
    //删除用户
    async deleteUser(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteUser(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤状态
    formatStatus (row, column){
      return row.ticketStatus=='0'?<span class="error">待派单</span>
            :row.ticketStatus=='1'?<span class="warning">已派单待处理</span>
            :row.ticketStatus=='2'?<span>处理中</span>
            :row.ticketStatus=='3'?<span class="primary">已处理待审核</span>
            :row.ticketStatus=='4'?<span class="success">审核通过</span>
            :row.ticketStatus=='5'?<span class="error">审核不通过</span>:<span class="info">已取消</span>
    },
    //过滤等级
    formatLevel (row, column){
      return row.ticketLevel=='1'?<span class="error">特急</span>
            :row.ticketLevel=='2'?<span class="warning">紧急</span>
            :row.ticketLevel=='3'?<span class="primary">较急</span>:<span class="success">一般</span>
    },
    //过滤类型
    formatType (row, column){
      let result = vm.dicObj.typeData.filter(item => item.dictValue == row.ticketType)
      return <span class="warning">{result[0]?.dictName}</span>||'-'
    },
    //过滤时间
    formatTime2(row,column){
      return row.createTime?formatTime(row.createTime,'Y-M-D h:m:s'):'-'
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        "areaId": vm.$store.state.csType,
        "startTime": vm.keyWord.time?vm.keyWord.time[0]:'',
        "endTime": vm.keyWord.time?vm.keyWord.time[1]:'',
        "homeId": vm.keyWord.homeId,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits,
        "ticketCode": vm.keyWord.code,
        "ticketLevel": vm.keyWord.level,
        "ticketState": vm.keyWord.status,
        "ticketType": vm.keyWord.type
      }
      const res = await apiUrl.ticketListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics(){
      let dataObj = ['TICKET_TYPE']
      const res = await apiUrl.getDicts(dataObj)
      if(res?.code==200){
        vm.dicObj.typeData = res.data.TICKET_TYPE||[];//类型
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.system-ticket{

}
</style>
